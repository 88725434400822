import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostTitle from "../components/post-title"

export default ({ data }) => {
  const node = data.markdownRemark
  return (
  <Layout>
      <SEO
        title={node.frontmatter.title}
        description={node.frontmatter.excerpt}
      />
      <div className={`flex flex-col items-center w-full`}>
        <article className={`max-w-3xl`}>
           <div className={`flex flex-col bg-white shadow p-6 md:p-10`}>
              <PostTitle title={node.frontmatter.name}/>
              <div id="postContent" className={`mb-8`}>
                <div
                  className="markdown"
                  dangerouslySetInnerHTML={{ __html: node.html }}
                />
                </div>
              </div>
          </article>
      </div>
  </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        name
        date(formatString: "MMM D YYYY")
        category
        excerpt
      }
    }
  }
`
